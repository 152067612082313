import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Reveal from '../components/reveal'
import clients from '../../content/settings/clients.json'

export default ({ location }) => {
    function chunk(arr, n) {
        return arr
            .slice(0, ((arr.length + n - 1) / n) | 0)
            .map(function (c, i) {
                return arr.slice(n * i, n * i + n)
            })
    }
    const logos = chunk(clients.logos, 4)

    useEffect(() => {
        const id = setInterval(() => {
            var current = document.querySelector('.clients.active')
            var next = current.nextElementSibling
            if (!next) {
                next = document.querySelector('.clients:first-child')
            }
            current.classList.remove('active')
            next.classList.add('active')
        }, 3000)
        return () => clearInterval(id)
    }, [])

    return (
        <Layout title="About" location={location}>
            <section className="section section-hero">
                <Reveal className="wrap">
                    <h1 className="label">About</h1>
                    <h2 className="title title-about">
                        We believe that any successful project should start with
                        a successful conversation.
                    </h2>
                    <div className="container">
                        <p>
                            So we always begin by figuring out what makes you
                            and your goals unique. As a team of seasoned
                            professionals, we may have hundreds of completed
                            projects in our portfolio, but well never assume
                            yours is just business as usual. You dont fit in a
                            box, so we wont cram you into a template. Because
                            while this work might be usual to us, we still do it
                            unusually well.
                        </p>
                    </div>
                </Reveal>
            </section>
            <section className="section section-yellow">
                <div className="wrap">
                    <div className="members">
                        <Reveal className="team-label">
                            <h2 className="title" from="bottom">
                                Meet our unusually great team
                            </h2>
                        </Reveal>
                        <Reveal className="member member-one" from="bottom">
                            <img
                                src="/img/tatjana-volbeke.png"
                                width="216"
                                height="216"
                                alt="Tatjana Volbeke"
                                title="Tatjana Volbeke"
                            />
                            <h3>Tatjana Volbeke</h3>
                            <p>
                                <b>Founder, Director</b>
                                <br /> <br />
                                "Good UX always feels effortless, even though
                                creating it rarely is. Understanding how users
                                spend their time online isn't everything.
                                There's a lot of strategy, innovation, design
                                thinking and management that goes into creating
                                websites and apps that deliver outstanding
                                experiences, and I'm passionate about it every
                                step of the way."
                            </p>
                        </Reveal>
                        <Reveal className="member" from="bottom" delay="0.2">
                            <img
                                src="/img/edmundas-stundzius.png"
                                width="216"
                                height="216"
                                alt="Edmundas Stundzius"
                                title="Edmundas Stundzius"
                            />
                            <h3>Edmundas Stundzius</h3>
                            <p>
                                <b>Product Design</b>
                                <br /> <br />
                                "No other human experience drives me to get out
                                of bed more than seeing the things I build being
                                used and enjoyed. While my career began with a
                                focus on graphic design, the love for tech
                                eventually led me to work with startups and
                                product teams. Solutions that are functional
                                don't have to be boring, and that's the mantra I
                                follow to make things unlike usual."
                            </p>
                        </Reveal>
                        <Reveal className="member" from="bottom" delay="0.2">
                            <img
                                src="/img/arnas-stucinskas.jpg"
                                width="216"
                                height="216"
                                alt="Arnas Stucinskas"
                                title="Arnas Stucinskas"
                            />
                            <h3>Arnas Stucinskas</h3>
                            <p>
                                <b>Web Development</b>
                                <br /> <br />
                                "The world of coding is extremely dynamic. I
                                can’t count the times I’ve changed the way I do
                                things throughout my programming career. But
                                each time I’m excited and inspired by innovation
                                and new better ways of working which enables me
                                to implement the most creative ideas."
                            </p>
                        </Reveal>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="wrap">
                    <Reveal>
                        <h2 className="label">
                            years of experience working with
                        </h2>
                    </Reveal>
                    <Reveal from="bottom" className="clients-holder">
                        {logos.map((chunk, k) => {
                            return (
                                <div key={k} className="clients active">
                                    {chunk.map((logo, l) => {
                                        return (
                                            <div key={l} className="client">
                                                <img src={logo.image} alt="" />
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </Reveal>
                </div>
            </section>
            <section className="section section-black">
                <div className="wrap">
                    <Reveal>
                        <h2 className="label">THIS IS WHAT WE BELIEVE IN</h2>
                    </Reveal>
                    <div className="rows">
                        <Reveal from="bottom" className="row">
                            <div className="row-left">01</div>
                            <div className="row-right">
                                <h3 className="row-title">
                                    Collaborate, inside and out
                                </h3>
                                <p>
                                    We are more creative as a team than as
                                    individuals, and this includes our clients.
                                    Collaboration and mutual appreciation are
                                    necessary for success.
                                </p>
                            </div>
                        </Reveal>
                        <Reveal from="bottom" className="row">
                            <div className="row-left">02</div>
                            <div className="row-right">
                                <h3 className="row-title">Make pretty work</h3>
                                <p>
                                    Good design is not choosing between beauty
                                    or functionality. It’s making both work hand
                                    in hand.
                                </p>
                            </div>
                        </Reveal>
                        <Reveal from="bottom" className="row">
                            <div className="row-left">03</div>
                            <div className="row-right">
                                <h3 className="row-title">
                                    Be remote, not distant
                                </h3>
                                <p>
                                    Co-location is a thing of the past. We find
                                    strength in different geographies and
                                    backgrounds of our team members.
                                </p>
                            </div>
                        </Reveal>
                        <Reveal from="bottom" className="row">
                            <div className="row-left">04</div>
                            <div className="row-right">
                                <h3 className="row-title">
                                    Think laterally, not literally
                                </h3>
                                <p>
                                    Cookie-cutter just won’t cut it. We turn
                                    every assumption and dominant idea on its
                                    head and see where that takes us.
                                </p>
                            </div>
                        </Reveal>
                        <Reveal from="bottom" className="row">
                            <div className="row-left">05</div>
                            <div className="row-right">
                                <h3 className="row-title">Make things human</h3>
                                <p>
                                    Understanding human stories helps build
                                    powerful experiences. We strive to meet the
                                    needs of the people in every project we
                                    encounter.
                                </p>
                            </div>
                        </Reveal>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
